<template>
  <v-container fluid :class="$vuetify.breakpoint.smAndDown ? '' : 'pl-0 pb-16'">
    <draggable
      :list="cards"
      group="cards"
      :class="
        $vuetify.breakpoint.smAndDown
          ? ''
          : 'row fill-width overflow-x-auto no-wrap'
      "
      class="mb-16"
      draggable=".item:not(.non-drag)"
      @end="dragColumn(cards.id, $event)"
    >
      <div
        v-for="(card, i) in cards"
        :key="i"
        :class="!card.default ? 'item' : 'non-drag'"
      >
        <v-card
          :outlined="!$vuetify.breakpoint.smAndDown"
          :elevation="$vuetify.breakpoint.smAndDown ? 0 : 1"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '330'"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-1' : ''"
          :min-height="$vuetify.breakpoint.smAndDown ? '100%' : '53vh'"
        >
          <v-card-title class="pa-0">
            <v-text-field
              v-model="card.title"
              v-if="card.check && !card.default"
              outlined
              dense
              autofocus
              hide-details
              @blur="saveColumn(card)"
            ></v-text-field>
            <v-card
              v-else-if="card.default"
              width="100%"
              height="52"
              elevation="0"
              color="grey lighten-4 text-none py-4 pl-3 body-2 font-weight-medium"
              ><span class="mt-2">
                {{ card.title }}
              </span>
              <v-btn
                elevation="0"
                fab
                outlined
                :width="card.tasks.length > 99 ? 25 : 20"
                :height="card.tasks.length > 99 ? 25 : 20"
                class="ml-1 text-caption font-weight-regular disable-events"
                color="primary"
              >
                {{ card.tasks.length }}</v-btn
              >
            </v-card>
            <v-card
              v-else
              width="100%"
              height="52"
              elevation="0"
              color="grey lighten-4 text-none py-4 pl-3 body-2 font-weight-medium"
            >
              <div>
                <a class="mt-2" @click="editColumn(card)">
                  {{ card.title }}
                </a>
                <v-btn
                  elevation="0"
                  fab
                  outlined
                  width="20"
                  height="20"
                  class="ml-1 text-caption font-weight-regular"
                  color="primary"
                  @click="editColumn(card)"
                >
                  {{ card.tasks.length }}</v-btn
                >
                <v-btn
                  class="float-right pb-4"
                  aria-label="delete"
                  icon
                  @click.native="deleteCard(i, card)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>
            </v-card>
          </v-card-title>

          <v-spacer></v-spacer>

          <v-card-text
            class="pa-0 pt-2 timeline-darg"
            :class="$vuetify.breakpoint.smAndDown ? '' : 'timeline-darg'"
          >
            <draggable
              :list="card.tasks"
              group="tasks"
              @change="draggedCard($event, i)"
              :class="$vuetify.breakpoint.smAndDown ? '' : 'timeline-darg'"
            >
              <v-col
                v-for="(task, j) in card.tasks"
                :key="j"
                cols="12"
                :class="
                  !task.is_complete
                    ? $vuetify.breakpoint.smAndDown
                      ? 'item px-0'
                      : 'item'
                    : $vuetify.breakpoint.smAndDown
                      ? ' px-0'
                      : ''
                "
              >
                <v-card
                  :width="$vuetify.breakpoint.smAndDown ? '100%' : '300'"
                  min-height="70"
                  class="rounded-lg pl-3 pr-1"
                >
                  <v-row class="pt-2">
                    <v-col class="py-0 pl-4" @click="editTask(task)">
                      <a
                        class="black--text font-weight-medium multiline-ellipsis"
                      >
                        {{ task.task_name }}</a
                      >
                    </v-col>
                  </v-row>
                  <v-row class="mt-2 remove-padding">
                    <v-col class="py-0 pl-4 pr-0" cols="4">
                      <v-select
                        class="pa-0 body-2 font-weight-medium"
                        max-width="60px"
                        v-model="task.progress"
                        :items="taskProgressList"
                        :menu-props="{ bottom: true, offsetY: true }"
                        solo
                        flat
                        dense
                        hide-details
                        append-icon
                        @change="onChangeProgress(task)"
                      >
                        <template v-slot:prepend-inner>
                          <v-progress-circular
                            color="success"
                            :width="2"
                            :size="22"
                            :value="task.progress"
                            :rotate="-90"
                          ></v-progress-circular>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col class="py-0 pt-2">
                      To Do :
                      <span class="body-2 font-weight-medium"
                        >{{ task.todo_items ? task.todo_items.length : 0 }}
                      </span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </draggable>
          </v-card-text>
        </v-card>
      </div>
      <div
        class="non-drag"
        v-if="this.movedTasK.length === 0 && checkPermission('createTask')"
      >
        <v-card
          outlined
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '310'"
          min-height="56"
        >
          <v-card-title
            :class="!isEdit ? 'pa-0' : 'pa-2'"
            color="grey lighten-4"
          >
            <v-card
              v-if="!isEdit"
              @click="isEdit = true"
              width="100%"
              height="52"
              elevation="0"
              color="grey lighten-4 text-none py-4 pl-3 body-2 font-weight-medium"
            >
              <v-row>
                <v-col>
                  <span class="mt-2"> NEW COLUMN </span>
                </v-col>
                <v-col class="d-flex justify-end mr-3"
                  ><v-icon size="21">mdi-plus</v-icon></v-col
                >
              </v-row></v-card
            >
            <v-text-field
              outlined
              dense
              hide-details
              autofocus
              v-model="colTitle"
              v-else
              @blur="isEdit = false"
              @keypress.enter="addCard"
            >
              <template v-slot:append>
                <v-icon @click="addCard"> mdi-check </v-icon></template
              ></v-text-field
            >
          </v-card-title>

          <v-spacer></v-spacer>
        </v-card>
      </div>
    </draggable>
    <v-layout>
      <div
        v-if="$vuetify.breakpoint.mdAndUp && addTaskDialog"
        id="addTaskPanel"
      >
        <AddTask
          @taskAdded="taskAdded"
          @hideTaskDialog="hideTaskDialog"
          @closeTaskDialog="closeTaskDialog"
          :project-details="projectDetails"
          :edit-task-details="editTaskData"
        />
      </div>
      <v-dialog
        v-if="!$vuetify.breakpoint.mdAndUp && addTaskDialog"
        v-model="addTaskDialog"
        fullscreen
        width="auto"
        height="auto"
      >
        <AddTask
          @taskAdded="taskAdded"
          @hideTaskDialog="hideTaskDialog"
          @closeTaskDialog="closeTaskDialog"
          :edit-task-details="editTaskData"
        />
      </v-dialog>
    </v-layout>
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown"
      fixed
      horizontal
      height="70"
      class="px-2 py-4"
    >
      <v-row class="px-4 py-2">
        <v-btn
          color="primary"
          class="text-none rounded-lg font-weight-regular text-h6 white--text"
          block
          height="50"
          @click="createTicket"
          ><span class="white--text">{{ $t("createTicket") }}</span
          ><v-icon color="white">mdi-plus</v-icon>
        </v-btn>
      </v-row>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import Draggable from "vuedraggable";
import UserDataMixin from "@/mixins/UserData";
export default {
  components: {
    Draggable,
    AddTask: () => import("./AddTask.vue"),
  },
  mixins: [UserDataMixin],
  props: {
    projectDetails: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    addTaskDialog: false,
    cards: [
      {
        title: "To Do",
        tasks: [],
        default: false,
        id: 0,
      },
      {
        title: "In Progress",
        tasks: [],
        default: false,
        id: 1,
      },
      {
        title: "Done",
        tasks: [],
        default: false,
        id: 2,
      },
    ],
    colTitle: "NEW COLUMN",
    isEdit: false,
    taskProgressList: ["0%", "25%", "50%", "75%", "100%"],
    taskTableData: [],
    movedTasK: [],
    editTaskData: {},
  }),
  async created() {
    await this.getTaskData();
    this.movedTasK = this.taskTableData.filter((item) => {
      return item.step_id && item.step_id !== 0;
    });
    await this.kanbanBoardData();
    this.$nextTick(() => {
      this.adjustColumnHeight();
    });
  },
  methods: {
    createTicket() {
      this.$router.push("/createTicket").catch();
    },
    kanbanBoardData() {
      if (!this.projectDetails.options) this.updateProject();
      else if (this.projectDetails.options.steps) {
        this.cards = this.projectDetails.options.steps.map((item) => {
          let card = Object.assign({}, item);
          card.tasks = [];
          for (let i = 0; i < this.taskTableData.length; i++) {
            if (card.id === this.taskTableData[i].step_id)
              card.tasks.push(this.taskTableData[i]);
            else if (!this.taskTableData[i].step_id && card.id === 0)
              card.tasks.push(this.taskTableData[i]);
          }
          card.default =
            this.movedTasK.length > 0 || !this.checkPermission("createTask")
              ? true
              : false;
          return card;
        });
      }
    },
    taskAdded() {
      this.addTaskDialog = false;
      this.$emit("taskAdded");
    },
    //Hides dialog and updates data
    async hideTaskDialog() {
      await this.getTaskData();
      await this.kanbanBoardData();
      this.getProjectData();
      this.addTaskDialog = false;
    },
    //Hides dialog with no data update
    closeTaskDialog() {
      this.addTaskDialog = false;
    },
    editTask(item) {
      window.scrollTo(0, 0);
      this.addTaskDialog = true;
      this.editTaskData = item;
    },
    async getTaskData() {
      this.taskTableData = [];
      const projectDataResponse = await this.$store.dispatch(
        "getProjectTaskById",
        this.projectDetails,
      );
      if (projectDataResponse.status !== "error") {
        projectDataResponse.forEach((element) => {
          if (!element.progress) element.progress = "0%";
          else element.progress = element.progress + "%";
          if (!element.start_date) element.start_date = null;
          else
            element.start_date = this.moment(
              new Date(element.start_date),
            ).format("YYYY-MM-DD");
          if (!element.end_date) element.end_date = null;
          else
            element.end_date = this.moment(new Date(element.end_date)).format(
              "YYYY-MM-DD",
            );
        });
        this.taskTableData = projectDataResponse;
      }
    },
    async updateProject() {
      let projectCards = this.cards.map((item) => {
        item.tasks = [];
        item.default =
          this.movedTasK.length > 0 || !this.checkPermission("createTask")
            ? true
            : false;
        for (let i = 0; i < this.taskTableData.length; i++) {
          if (item.id === this.taskTableData[i].step_id)
            item.tasks.push(this.taskTableData[i]);
          else if (!this.taskTableData[i].step_id && item.id === 0)
            item.tasks.push(this.taskTableData[i]);
        }
        let card = Object.assign({}, item);
        delete card.tasks;
        delete card.default;
        return card;
      });

      const projectDetails = {
        project_id: this.projectDetails.project_id,
        options: { steps: projectCards },
      };
      this.projectDetails.options = { steps: projectCards };
      const updateProjectResp = await this.$store.dispatch(
        "updateProject",
        projectDetails,
      );
      if (updateProjectResp.status === "error") {
        console.log("error in project update");
      } else {
        this.getProjectData();
      }
    },
    async getProjectData() {
      const projectDataResponse =
        await this.$store.dispatch("getCompanyProject");
      if (projectDataResponse.status !== "error") {
        if (projectDataResponse && projectDataResponse.length === 0)
          this.isProjectEmpty = true;
        else this.isProjectEmpty = false;
        this.projectData = false;
        projectDataResponse.forEach((item) => {
          item.progress = item.no_of_task
            ? parseInt((item.no_of_completed_task / item.no_of_task) * 100)
            : 0;
          item.tasks = item.no_of_task ? item.no_of_task : 0;
          item.attachments = 3;
          if (item.status === null) item.status = "Planning";
        });
        this.$store.commit("setProjectData", projectDataResponse);
      }
    },
    async dragColumn(list, ev) {
      for (let i = 0; i < this.cards.length; i++) {
        if (ev.oldIndex === this.cards[i].id) {
          this.cards[i].id = ev.newIndex;
        } else if (ev.newIndex === this.cards[i].id) {
          this.cards[i].id = ev.oldIndex;
        }
      }
      await this.updateProject();
      this.$nextTick(() => {
        this.adjustColumnHeight();
      });
    },
    async draggedCard(card, ev) {
      if (card.added) {
        card.added.element.step_id = ev;
        let updateObject = { task_id: card.added.element.task_id };
        updateObject["step_id"] = card.added.element.step_id;
        if (this.movedTasK.length === 0 || card.added.element.step_id === 0) {
          this.movedTasK = this.taskTableData.filter((item) => {
            return item.step_id && item.step_id !== 0;
          });
          await this.kanbanBoardData();
        }
        const updatetaskResp = await this.$store.dispatch(
          "updateProjectTask",
          updateObject,
        );
        if (updatetaskResp.status === "error") {
          console.log("error in task update");
        } else {
          this.getProjectData();
        }
        this.$nextTick(() => {
          this.adjustColumnHeight();
        });
      }
    },
    async addCard() {
      const card = {
        title: this.colTitle,
        tasks: [],
        default: false,
        id: this.cards.length,
      };
      this.cards.push(card);
      await this.updateProject();
      this.colTitle = "NEW COLUMN";
      this.isEdit = false;
    },
    editColumn(item) {
      const index = this.cards.indexOf(item);
      item.check = true;
      this.cards[index] = item;
      const tempArr = [...this.cards];
      this.cards = [];
      this.cards = tempArr;
    },
    async saveColumn(item) {
      const index = this.cards.indexOf(item);
      item.check = false;
      this.cards[index] = item;
      const tempArr = [...this.cards];
      this.cards = [];
      this.cards = tempArr;
      await this.updateProject();
    },
    async isCompleteTask(item) {
      if (item.is_complete) {
        item.step_id = this.cards.length - 1;
        item.progress = "100%";
        this.cards = [];
        this.$nextTick(() => {
          this.kanbanBoardData();
          this.$nextTick(() => {
            this.adjustColumnHeight();
          });
        });

        let updateObject = {
          task_id: item.task_id,
          is_complete: item.is_complete,
          progress: 100,
        };
        updateObject["step_id"] = item.step_id;
        const updatetaskResp = await this.$store.dispatch(
          "updateProjectTask",
          updateObject,
        );
        if (updatetaskResp.status === "error") {
          console.log("error in task update");
        } else {
          this.getProjectData();
        }
      }
    },
    async onChangeProgress(item) {
      let updateObject = {
        task_id: item.task_id,
        progress: item.progress.slice(0, -1),
      };

      if (item.progress === "100%") {
        item.is_complete = true;
        updateObject.is_complete = true;
      } else {
        item.is_complete = false;
        updateObject.is_complete = false;
      }

      const updatetaskResp = await this.$store.dispatch(
        "updateProjectTask",
        updateObject,
      );
      if (updatetaskResp.status === "error") {
        console.log("error in task update");
      }
    },
    async deleteCard(index, item) {
      // item.check = false;
      let lastCards = this.cards.slice(index + 1);
      let i = index;
      lastCards.forEach((element) => {
        element.id = i;
        i++;
      });
      this.cards = [...this.cards.slice(0, index), ...lastCards];
      await this.updateProject();
      this.$nextTick(() => {
        this.adjustColumnHeight();
      });
    },
    deleteTask(cardIndex, taskIndex) {
      const card = this.cards[cardIndex];
      card.tasks = [
        ...card.tasks.slice(0, taskIndex),
        ...card.tasks.slice(taskIndex + 1),
      ];
    },
    adjustColumnHeight() {
      const dividerAfterElements = document.querySelectorAll(".timeline-darg");
      dividerAfterElements.forEach((element) => {
        element.style.minHeight = `${
          element.parentElement.parentElement.offsetHeight - 65
        }px`;
      });
    },
  },
};
</script>
<style lang="scss">
.fill-width {
  overflow-x: auto;
  flex-wrap: nowrap !important;
  overflow-y: clip;
}
.remove-padding {
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding: 0 !important;
  }
}
</style>
<style scoped>
.disable-events {
  pointer-events: none;
}
.v-data-table__empty-wrapper {
  display: none;
}
#add-task {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #0073d1;
  text-transform: capitalize;
}
.empty-task-state {
  display: flex;
  justify-content: center;
  height: auto;
  align-items: center;
}
#addTaskPanel {
  position: absolute;
  top: 0;
  right: 0;
  width: 560px;
  height: calc(100vh);
  transform-origin: center center;
  max-width: 560px;
  margin-right: 0px;
  margin-top: -60px;
  margin-left: 0px;
  margin-bottom: -20px;
  z-index: 100;
  background-color: #fff;
  border-left: 1px solid #e0e0e0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;
}
</style>
