var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      class: _vm.$vuetify.breakpoint.smAndDown ? "" : "pl-0 pb-16",
      attrs: { fluid: "" },
    },
    [
      _c(
        "draggable",
        {
          staticClass: "mb-16",
          class: _vm.$vuetify.breakpoint.smAndDown
            ? ""
            : "row fill-width overflow-x-auto no-wrap",
          attrs: {
            list: _vm.cards,
            group: "cards",
            draggable: ".item:not(.non-drag)",
          },
          on: {
            end: function ($event) {
              return _vm.dragColumn(_vm.cards.id, $event)
            },
          },
        },
        [
          _vm._l(_vm.cards, function (card, i) {
            return _c(
              "div",
              { key: i, class: !card.default ? "item" : "non-drag" },
              [
                _c(
                  "v-card",
                  {
                    class: _vm.$vuetify.breakpoint.smAndDown ? "mt-1" : "",
                    attrs: {
                      outlined: !_vm.$vuetify.breakpoint.smAndDown,
                      elevation: _vm.$vuetify.breakpoint.smAndDown ? 0 : 1,
                      width: _vm.$vuetify.breakpoint.smAndDown ? "100%" : "330",
                      "min-height": _vm.$vuetify.breakpoint.smAndDown
                        ? "100%"
                        : "53vh",
                    },
                  },
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "pa-0" },
                      [
                        card.check && !card.default
                          ? _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                dense: "",
                                autofocus: "",
                                "hide-details": "",
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.saveColumn(card)
                                },
                              },
                              model: {
                                value: card.title,
                                callback: function ($$v) {
                                  _vm.$set(card, "title", $$v)
                                },
                                expression: "card.title",
                              },
                            })
                          : card.default
                          ? _c(
                              "v-card",
                              {
                                attrs: {
                                  width: "100%",
                                  height: "52",
                                  elevation: "0",
                                  color:
                                    "grey lighten-4 text-none py-4 pl-3 body-2 font-weight-medium",
                                },
                              },
                              [
                                _c("span", { staticClass: "mt-2" }, [
                                  _vm._v(" " + _vm._s(card.title) + " "),
                                ]),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "ml-1 text-caption font-weight-regular disable-events",
                                    attrs: {
                                      elevation: "0",
                                      fab: "",
                                      outlined: "",
                                      width: card.tasks.length > 99 ? 25 : 20,
                                      height: card.tasks.length > 99 ? 25 : 20,
                                      color: "primary",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(card.tasks.length))]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "v-card",
                              {
                                attrs: {
                                  width: "100%",
                                  height: "52",
                                  elevation: "0",
                                  color:
                                    "grey lighten-4 text-none py-4 pl-3 body-2 font-weight-medium",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "mt-2",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editColumn(card)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(card.title) + " ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "ml-1 text-caption font-weight-regular",
                                        attrs: {
                                          elevation: "0",
                                          fab: "",
                                          outlined: "",
                                          width: "20",
                                          height: "20",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editColumn(card)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(card.tasks.length))]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "float-right pb-4",
                                        attrs: {
                                          "aria-label": "delete",
                                          icon: "",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.deleteCard(i, card)
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-delete-outline"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-card-text",
                      {
                        staticClass: "pa-0 pt-2 timeline-darg",
                        class: _vm.$vuetify.breakpoint.smAndDown
                          ? ""
                          : "timeline-darg",
                      },
                      [
                        _c(
                          "draggable",
                          {
                            class: _vm.$vuetify.breakpoint.smAndDown
                              ? ""
                              : "timeline-darg",
                            attrs: { list: card.tasks, group: "tasks" },
                            on: {
                              change: function ($event) {
                                return _vm.draggedCard($event, i)
                              },
                            },
                          },
                          _vm._l(card.tasks, function (task, j) {
                            return _c(
                              "v-col",
                              {
                                key: j,
                                class: !task.is_complete
                                  ? _vm.$vuetify.breakpoint.smAndDown
                                    ? "item px-0"
                                    : "item"
                                  : _vm.$vuetify.breakpoint.smAndDown
                                  ? " px-0"
                                  : "",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "rounded-lg pl-3 pr-1",
                                    attrs: {
                                      width: _vm.$vuetify.breakpoint.smAndDown
                                        ? "100%"
                                        : "300",
                                      "min-height": "70",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "pt-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0 pl-4",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editTask(task)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "black--text font-weight-medium multiline-ellipsis",
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(task.task_name)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-2 remove-padding" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0 pl-4 pr-0",
                                            attrs: { cols: "4" },
                                          },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "pa-0 body-2 font-weight-medium",
                                              attrs: {
                                                "max-width": "60px",
                                                items: _vm.taskProgressList,
                                                "menu-props": {
                                                  bottom: true,
                                                  offsetY: true,
                                                },
                                                solo: "",
                                                flat: "",
                                                dense: "",
                                                "hide-details": "",
                                                "append-icon": "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onChangeProgress(
                                                    task
                                                  )
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "prepend-inner",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-progress-circular",
                                                          {
                                                            attrs: {
                                                              color: "success",
                                                              width: 2,
                                                              size: 22,
                                                              value:
                                                                task.progress,
                                                              rotate: -90,
                                                            },
                                                          }
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: task.progress,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    task,
                                                    "progress",
                                                    $$v
                                                  )
                                                },
                                                expression: "task.progress",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { staticClass: "py-0 pt-2" },
                                          [
                                            _vm._v(" To Do : "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "body-2 font-weight-medium",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    task.todo_items
                                                      ? task.todo_items.length
                                                      : 0
                                                  ) + " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          this.movedTasK.length === 0 && _vm.checkPermission("createTask")
            ? _c(
                "div",
                { staticClass: "non-drag" },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        outlined: "",
                        width: _vm.$vuetify.breakpoint.smAndDown
                          ? "100%"
                          : "310",
                        "min-height": "56",
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          class: !_vm.isEdit ? "pa-0" : "pa-2",
                          attrs: { color: "grey lighten-4" },
                        },
                        [
                          !_vm.isEdit
                            ? _c(
                                "v-card",
                                {
                                  attrs: {
                                    width: "100%",
                                    height: "52",
                                    elevation: "0",
                                    color:
                                      "grey lighten-4 text-none py-4 pl-3 body-2 font-weight-medium",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.isEdit = true
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", [
                                        _c("span", { staticClass: "mt-2" }, [
                                          _vm._v(" NEW COLUMN "),
                                        ]),
                                      ]),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex justify-end mr-3",
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "21" } },
                                            [_vm._v("mdi-plus")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  autofocus: "",
                                },
                                on: {
                                  blur: function ($event) {
                                    _vm.isEdit = false
                                  },
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.addCard.apply(null, arguments)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            { on: { click: _vm.addCard } },
                                            [_vm._v(" mdi-check ")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1445666793
                                ),
                                model: {
                                  value: _vm.colTitle,
                                  callback: function ($$v) {
                                    _vm.colTitle = $$v
                                  },
                                  expression: "colTitle",
                                },
                              }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "v-layout",
        [
          _vm.$vuetify.breakpoint.mdAndUp && _vm.addTaskDialog
            ? _c(
                "div",
                { attrs: { id: "addTaskPanel" } },
                [
                  _c("AddTask", {
                    attrs: {
                      "project-details": _vm.projectDetails,
                      "edit-task-details": _vm.editTaskData,
                    },
                    on: {
                      taskAdded: _vm.taskAdded,
                      hideTaskDialog: _vm.hideTaskDialog,
                      closeTaskDialog: _vm.closeTaskDialog,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.$vuetify.breakpoint.mdAndUp && _vm.addTaskDialog
            ? _c(
                "v-dialog",
                {
                  attrs: { fullscreen: "", width: "auto", height: "auto" },
                  model: {
                    value: _vm.addTaskDialog,
                    callback: function ($$v) {
                      _vm.addTaskDialog = $$v
                    },
                    expression: "addTaskDialog",
                  },
                },
                [
                  _c("AddTask", {
                    attrs: { "edit-task-details": _vm.editTaskData },
                    on: {
                      taskAdded: _vm.taskAdded,
                      hideTaskDialog: _vm.hideTaskDialog,
                      closeTaskDialog: _vm.closeTaskDialog,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "px-2 py-4",
              attrs: { fixed: "", horizontal: "", height: "70" },
            },
            [
              _c(
                "v-row",
                { staticClass: "px-4 py-2" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "text-none rounded-lg font-weight-regular text-h6 white--text",
                      attrs: { color: "primary", block: "", height: "50" },
                      on: { click: _vm.createTicket },
                    },
                    [
                      _c("span", { staticClass: "white--text" }, [
                        _vm._v(_vm._s(_vm.$t("createTicket"))),
                      ]),
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }